<template>
    <div class="doctor-form form-page">
        <Form v-if="!fetching" :mode="mode" :stored="data" />
        <div class="policy-box">
            <div class="AE item" @click="onOpenAEPopup">AE通報資訊</div>
            <div class="item reveal-information" @click="onOpenRevealInformationPopup">
                羅氏揭露訊息
            </div>
        </div>
    </div>
</template>

<script>
import Form from '@/components/Form.vue';
import { mapState, mapActions, mapMutations } from 'vuex';

export default {
    components: { Form },
    data() {
        return {
            data: undefined,
            fetching: false
        };
    },
    created() {
        this.fetchData();
    },
    computed: {
        ...mapState({
            mode: state => state.doctor.mode
        })
    },
    methods: {
        ...mapMutations({
            updateAEDisclaimerPopUpState: 'popup/updateAEDisclaimerPopUpState',
            updateRevealInformationPopupState: 'popup/updateRevealInformationPopupState'
        }),
        ...mapActions({
            apiClient: 'api/invoke'
        }),
        onOpenRevealInformationPopup() {
            this.updateRevealInformationPopupState(true);
        },
        async fetchData(retryTimes = 0) {
            this.fetching = true;
            const PAYLOAD = {
                category: 'Case',
                function: 'find',
                successMsg: '資料已匯入',
                data: this.$route.params.id
            };
            try {
                const RES = await this.apiClient(PAYLOAD);
                let DATA = RES.data.data.data;
                this.data = DATA;
                if (!DATA) {
                    DATA = RES.data;
                    this.data = {
                        CTNumber: DATA.CTNumber,
                        title: DATA.title,
                        doctorName: DATA.doctorName,
                        doctorUCI: DATA.doctorUCI,
                        hospital: DATA.hospital,
                        doctorAvatar: DATA.doctorAvatar
                    };
                }
            } catch (e) {
                if (retryTimes < 3) {
                    console.log('retry', retryTimes);
                    this.fetchData(retryTimes + 1);
                } else {
                    throw e;
                }
            }
            this.fetching = false;
        },
        onOpenAEPopup() {
            this.updateAEDisclaimerPopUpState(true);
        }
    }
};
</script>

<style lang="scss" scoped>
.policy {
    margin-top: 30px;
    margin-left: 20px;
    font-size: 12px;
    width: fit-content;
    text-decoration: underline;
    > * {
        cursor: pointer;
    }
}
</style>
